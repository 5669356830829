<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="box">
			<div class="form_box">
				<!-- {{form.permissions}} -->
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="角色名称" prop="name" :rules="rules.required">
						<el-input v-model="form.name" :readonly="isAdmin"></el-input>
					</el-form-item>
					<el-form-item label="权限">
						<el-tree :data="powerData" :default-checked-keys="form.permissions" check-strictly show-checkbox
						default-expand-all node-key="id" ref="tree" highlight-current :props="{children: 'children', label: 'title'}"
						@check-change="handleCheckChange" check-on-click-node :expand-on-click-node="false"></el-tree>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">{{saveBtn}}</el-button>
						<el-button @click="$router.go(-1)">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules, // 表单校验
				title: this.$route.params.id ? "编辑角色" : "新增角色",
				saveBtn: this.$route.params.id ? "确认保存" : "确认创建",
				form: {
					id: this.$route.params.id,
					name: "",
					permissions: []
				},
				powerData: []
			};
		},
		computed: {
			treeCheckedKeys() {
				// 已选择的权限
				return this.getTreeCheckedKeys(this.form.permissions)
			},
			isAdmin() {
				return this.form.name == '超级管理员'
			}
		},
		mounted() {
			// 查询所有权限
			this.$api.system.getPowers().then(res => {
				this.powerData = res.data;
			});
			// 如果有id，查询当前角色的详情
			if (this.form.id) {
				this.$api.system.getRole({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
		},
		methods: {
			// 获取已选中的列表
			getTreeCheckedKeys(arr) {
				let keys = []
				for (let i in arr) {
					if (arr[i].permissions) {
						keys.push(arr[i].id)
					}
					if (arr[i].children && arr[i].children.length) {
						let childrenKeys = this.getTreeCheckedKeys(arr[i].children)
						keys = keys.concat(childrenKeys)
					}
				}
				return keys
			},
			onSubmit() {
				if (this.isAdmin) {
					// 必须勾选系统设置
					let arr = this.$refs.tree.getCheckedNodes();
					let sys = arr.find(item => item.title == '系统设置')
					if(!sys){
						this.$message.error('超级管理员必须拥有“系统设置”权限');
						return;
					}
				}
				
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.system.saveRole(this.form).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			handleCheckChange(node, checked) {
				if (checked) {
					if (this.form.permissions.indexOf(node.id) < 0) {
						this.form.permissions.push(node.id)
					}
				} else {
					let index = this.form.permissions.indexOf(node.id);
					if (index >= 0) {
						this.form.permissions.splice(index, 1)
					}
				}
				// console.log(this.form.permissions)
			}
		}
	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}

	.box {
		flex-grow: 1;
		overflow: auto;
	}
</style>
